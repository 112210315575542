@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600;700;800;900&display=swap");

/* Basic editor styles */
.ProseMirror {
  padding: 1.5rem;
  min-height: 30rem;
  font-family: "Outfit", sans-serif;
  border: 1px solid rgba(13, 13, 13, 0.3);
  border-radius: 6px;
  font-size: 1rem;
  line-height: 1.5;
  background: #fff;
  color: #1a1a1a;
}
.ProseMirror:focus {
  outline-color: #32ab80;
}
.menu-bar {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  border-bottom: 1px solid rgba(13, 13, 13, 0.3);
  padding: 0.5rem 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-bar button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: large;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: background 0.2s ease;
}
.menu-bar button:hover {
  background: rgba(13, 13, 13, 0.1);
}
.menu-bar button.is-active {
  background: rgba(13, 13, 13, 0.2);
}
.ProseMirror > * + * {
  margin-top: 0.75em;
}
.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}
.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}
.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}
.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}
.ProseMirror img {
  max-width: 100%;
  height: auto;
}
.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}
.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}
